import React from 'react';

import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { ProtocolValidationIndicatorWithPopover } from 'client/app/components/ValidationIndicator/ValidationIndicator';
import Colors from 'common/ui/Colors';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import { TemplateWorkflowIcon } from 'common/ui/icons/TemplateWorkflow';

type Props = {
  protocolInstanceName: string;
  handleOnSimulate: () => void;
  handleValidationErrorClick: (elementId: string) => void;
  formatErrorTitle: (elementId: string) => string;
  parametersLoading: boolean;
};

export const EditProtocolInstanceHeader = ({
  handleOnSimulate,
  protocolInstanceName,
  parametersLoading,
  handleValidationErrorClick,
  formatErrorTitle,
}: Props) => {
  return (
    <Wrapper>
      <StyledTemplateWorkflowIcon />
      <TypographyWrapper>
        <ProtocolHeader variant="h4">{protocolInstanceName}</ProtocolHeader>
        <Typography variant="body2">Modify the inputs of your protocol</Typography>
      </TypographyWrapper>

      <RightContentWrapper>
        <StatusButtonsWrapper>
          <ProtocolValidationIndicatorWithPopover
            isLoading={parametersLoading}
            onClickElementError={handleValidationErrorClick}
            formatErrorTitle={formatErrorTitle}
          />
        </StatusButtonsWrapper>
        <SimulateButton
          onClick={handleOnSimulate}
          color="primary"
          size="medium"
          variant="extended"
        >
          Simulate protocol
        </SimulateButton>
      </RightContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(4),
  borderBottom: `1px solid ${Colors.GREY_30}`,
  padding: theme.spacing(4, 6),
}));

const TypographyWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const SimulateButton = styled(Fab)(() => ({
  marginLeft: 'auto',
}));

const ProtocolHeader = styled(TypographyWithTooltip)(() => ({
  fontWeight: 600,
}));

const StyledTemplateWorkflowIcon = styled(TemplateWorkflowIcon)(() => ({
  color: '#1773C9',
}));

const RightContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  marginLeft: 'auto',
}));

const StatusButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(6),
}));
