import React from 'react';

import { styled } from '@mui/material/styles';

import { EditProtocolInstance } from 'client/app/apps/protocols/EditProtocolInstance';
import { useGetDataAndInitialiseState } from 'client/app/apps/protocols/useGetData';
import UIErrorBox from 'client/app/components/UIErrorBox';
import LinearProgress from 'common/ui/components/LinearProgress';

type Props = {
  id: ProtocolInstanceId;
};

export default function EditProtocolInstanceScreen({ id: protocolInstanceId }: Props) {
  const result = useGetDataAndInitialiseState(protocolInstanceId);

  if (result.status === 'error') {
    return (
      <UIErrorBox>
        Sorry we could not load the protocol instance: {result.error.message}
      </UIErrorBox>
    );
  }

  return (
    <Wrapper>
      {result.status === 'protocol-instance-loading' ? (
        <LinearProgress />
      ) : (
        <EditProtocolInstance
          protocolInstanceQuery={result.protocolInstance}
          parametersLoading={result.status === 'parameters-loading'}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100%',
}));
