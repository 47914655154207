import React from 'react';

import DeleteOutline from '@mui/icons-material/DeleteOutline';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import MoreVert from '@mui/icons-material/MoreVert';
import TextFields from '@mui/icons-material/TextFields';
import { styled } from '@mui/material/styles';

import BreadcrumbsMenu from 'client/app/components/nav/breadcrumbs/components/BreadcrumbsMenu';
import { BreadcrumbsEntityDropdownProps } from 'client/app/components/nav/breadcrumbs/useBreadcrumbsEntity';
import stopPropagation from 'common/lib/stopPropagation';
import IconButton from 'common/ui/components/IconButton';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { usePopover } from 'common/ui/hooks/usePopover';

export default function ProtocolInstanceDropdown(_props: BreadcrumbsEntityDropdownProps) {
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    stopPropagation(event);
    onShowPopover(event);
  };

  const handleAnyMenuClick = (event: React.MouseEvent) => {
    stopPropagation(event);
    onHidePopover();
  };

  return (
    <>
      <MoreButton icon={<MoreVert />} size="xsmall" onClick={handleClick} />
      <BreadcrumbsMenu
        anchorEl={popoverAnchorElement}
        open={isPopoverOpen}
        onClose={onHidePopover}
        onClick={handleAnyMenuClick}
        onDoubleClick={stopPropagation}
      >
        {/* TODO: Implement these actions */}
        <MenuItemWithIcon icon={<FileCopyOutlined />} text="Copy Protocol" />
        <MenuItemWithIcon icon={<TextFields />} text="Rename Protocol" />
        <MenuItemWithIcon icon={<DeleteOutline />} text="Delete Protocol" />
      </BreadcrumbsMenu>
    </>
  );
}

const MoreButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(3),
}));
