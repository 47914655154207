import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import { EditProtocolInstanceHeader } from 'client/app/apps/protocols/EditProtocolInstanceHeader';
import { InputStep, InputStepSkeleton } from 'client/app/apps/protocols/InputStep';
import { InputStepList } from 'client/app/apps/protocols/InputStepList';
import {
  useComplexParameterEditorDialogManager,
  useProtocolsParamState,
  useStepsWithErrors,
} from 'client/app/apps/protocols/lib/utils';
import {
  OutputStepPreview,
  OutputStepPreviewSkeleton,
} from 'client/app/apps/protocols/OutputStepPreview';
import ProtocolInfo from 'client/app/apps/protocols/ProtocolInfo';
import { useUpdateProtocolInstance } from 'client/app/apps/protocols/useUpdateProtocolInstance';
import { useCurrentEntity } from 'client/app/components/nav/breadcrumbs/BreadcrumbsEntityContext';
import { ProtocolInstanceQuery } from 'client/app/gql';
import ParameterStateContextProvider from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { ProtocolStep } from 'common/types/Protocol';
import Colors from 'common/ui/Colors';

type Props = {
  protocolInstanceQuery: NonNullable<ProtocolInstanceQuery['protocolInstance']>;
  parametersLoading: boolean;
};

export const EditProtocolInstance = ({
  protocolInstanceQuery,
  parametersLoading,
}: Props) => {
  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);
  const parameters = useWorkflowBuilderSelector(state => state.parameters);
  const { debouncedSaveInstance, updatingProtocolInstanceLoading } =
    useUpdateProtocolInstance({ protocolInstanceQuery });
  const protocolInstance = protocolInstanceQuery.instance;
  const workflowSchema = protocolInstance.protocol.workflow.workflow.Schema;

  const dialogs = useComplexParameterEditorDialogManager();

  const stepErrors = useStepsWithErrors(protocolInstanceQuery);

  const steps = protocolInstance.protocol.protocol.steps;
  const { selectedStep, handleSelectStep } = useProtocolsParamState(steps);

  const handleOnSimulate = () => {};

  const getStepForErroredElement = (elementId: string): ProtocolStep | undefined => {
    const erroredStepId = [...stepErrors.entries()].find(([_, elementErrors]) => {
      return elementErrors.some(elementError => elementError.elementId === elementId);
    })?.[0];
    return steps.find(step => step.id === erroredStepId);
  };

  const handleValidationErrorClick = (elementId: string) => {
    const erroredStep = getStepForErroredElement(elementId);
    if (erroredStep) {
      handleSelectStep(erroredStep);
    }
  };

  const formatErrorTitle = (elementId: string) => {
    const erroredStep = getStepForErroredElement(elementId);
    return erroredStep?.displayName ?? '';
  };

  const { showInfo } = useCurrentEntity();
  // Because the `showInfo` state is lifted, it isn't reset when the component changes.
  // So we store and compare against the initial value to ensure we default to not showing
  const [initialShowInfo] = useState(showInfo);

  if (!workflowSchema?.inputs || !workflowSchema?.outputs) {
    // TODO - Handle this state better in the UI.
    return <p>No inputs or outputs found</p>;
  }

  return (
    <ParameterStateContextProvider
      parameters={parameters}
      elementInstances={elementInstances}
      connections={[]} // Uneccessary for Protocols app.
    >
      <EditProtocolInstanceHeader
        protocolInstanceName={protocolInstance.name}
        handleOnSimulate={handleOnSimulate}
        parametersLoading={parametersLoading || updatingProtocolInstanceLoading}
        handleValidationErrorClick={handleValidationErrorClick}
        formatErrorTitle={formatErrorTitle}
      />
      <Wrapper>
        <InputStepList
          steps={steps}
          onSelectStep={handleSelectStep}
          selectedStepId={selectedStep.id}
          stepErrors={stepErrors}
        />
        <InputsAndOutputsWrapper>
          {parametersLoading ? (
            <>
              <InputStepSkeleton stepDisplayName={selectedStep.displayName} />
              <OutputStepPreviewSkeleton />
            </>
          ) : (
            <>
              <InputStep
                step={selectedStep}
                stepErrors={stepErrors.get(selectedStep.id)}
                inputs={workflowSchema.inputs.filter(input =>
                  selectedStep.inputs.map(input => input.id).includes(input.id),
                )}
                outputs={workflowSchema.outputs.filter(output =>
                  selectedStep.outputs.map(output => output.id).includes(output.id),
                )}
                updateInstance={debouncedSaveInstance}
              />
              <OutputStepPreview noOutput={selectedStep.outputs.length === 0} />
            </>
          )}
        </InputsAndOutputsWrapper>
        {dialogs}
        {showInfo !== initialShowInfo && (
          <ProtocolInfo protocol={protocolInstance.protocol} />
        )}
      </Wrapper>
    </ParameterStateContextProvider>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplate: `
      "list inputsAndOutputs inputsAndOutputs" minmax(400px, 1200px)
      / auto 1fr 1fr`,
  padding: theme.spacing(8),
  gap: theme.spacing(7),
  height: '100%',
  overflow: 'auto',
  backgroundColor: Colors.GREY_10,
  position: 'relative',
}));

const InputsAndOutputsWrapper = styled('div')({
  gridArea: 'inputsAndOutputs',
  display: 'flex',
  overflowX: 'auto',
  zIndex: 1,
});
