import { useMutation } from '@apollo/client';

import { UPDATE_PROTOCOL_INSTANCE } from 'client/app/api/gql/mutations';
import { ProtocolInstanceQuery } from 'client/app/gql';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { ParameterValue } from 'common/types/bundle';
import { getElementId, getElementParameterName } from 'common/types/schema';
import useDebounce from 'common/ui/hooks/useDebounce';

const DEBOUNCED_AUTOSAVE_INTERVAL_MS = 1000;

type Props = {
  protocolInstanceQuery: NonNullable<ProtocolInstanceQuery['protocolInstance']>;
};

const useUpdateProtocolInstance = ({ protocolInstanceQuery }: Props) => {
  const dispatch = useWorkflowBuilderDispatch();
  const protocolInstance = protocolInstanceQuery.instance;
  const workflowSchema = protocolInstance.protocol.workflow.workflow.Schema;
  const parameters = useWorkflowBuilderSelector(state => state.parameters);
  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);

  const [updateProtocolInstance, { loading: updatingProtocolInstanceLoading }] =
    useMutation(UPDATE_PROTOCOL_INSTANCE);
  const debouncedSaveInstance = useDebounce(async () => {
    const updatedProtocolParams: { [inputId: string]: ParameterValue } = {};
    workflowSchema?.inputs?.forEach(input => {
      const elementInstanceId = getElementId(input.path);
      const parameterName = getElementParameterName(input.path);
      const elementInstanceName = elementInstances.find(
        elementInstance => elementInstance.Id === elementInstanceId,
      )?.name;
      if (elementInstanceName && parameterName) {
        const paramValue = parameters[elementInstanceName][parameterName];
        updatedProtocolParams[input.id] = paramValue;
      }
    });
    const { data } = await updateProtocolInstance({
      variables: {
        input: {
          id: protocolInstance.id,
          name: protocolInstance.name,
          editVersion: protocolInstance.editVersion,
          params: updatedProtocolParams,
        },
      },
    });

    const elementContextMap = data?.updateProtocolInstance.elementContextMap;
    const elementContextError = data?.updateProtocolInstance.elementContextError;

    if (elementContextMap) {
      dispatch({ type: 'updateElementsWithContexts', payload: elementContextMap });
    }
    if (elementContextError) {
      dispatch({ type: 'setElementContextError', payload: elementContextError });
    }
  }, DEBOUNCED_AUTOSAVE_INTERVAL_MS);

  return { debouncedSaveInstance, updatingProtocolInstanceLoading };
};

export { useUpdateProtocolInstance };
